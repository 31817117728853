import React, { createContext, useEffect, useRef, useState } from "react";
import { sendMsgToAI } from "./OpenAi";
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/system';
import { API } from '../components/MyApi';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import './Context.css'
import axios from "axios";
export const ContextApp = createContext();

const API_BASE_URL = process.env.REACT_APP_BASE_API_ENDPOINT;

const AppContext = ({ children }) => {

  const [showSlide, setShowSlide] = useState(false);
  const [loaderopen, setLoaderOpen] = React.useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [userHistory, setUserHistory] = React.useState([]);
  const [Mobile, setMobile] = useState(false);
  const [chatmemorylimit, setChatmemorylimit] = useState(0);
  const [sheetName, setSheetName] = useState('');
  const [user, setUser] = useState("");
  const [chatValue, setChatValue] = useState("");
  const [message, setMessage] = useState([]);
  const [email, setEmail] = useState("");
  const msgEnd = useRef(null);
  const [showLeftNav, setShowLeftNav] = useState(true);
  const [awsKeyFile, setAwsKeyFile] = useState("");
  const [modelOption, setModelOption] = useState("llama");
  

  const updateUser = (newUsername) => {
    setUser(newUsername);
  };

  useEffect(() => {
    console.log("Updated awsKeyFile:", awsKeyFile);
  }, [awsKeyFile]);

  useEffect(() => {
    console.log("Updated User History:", userHistory);
  }, [userHistory]);


  const getBaseFilename = (filename) => {
    const parts = filename.split('_');
    return parts.slice(0, -6).join('_');
  };

  const toggleLeftNav = () => {
    setShowLeftNav(prevState => !prevState);
    console.log(showLeftNav)
  };



  // const handleSend = async () => {
  //   setLoaderOpen(true);
  //   const newinput = chatValue;
  //   setChatValue("");

  //   setMessage((prevMessages) => [...prevMessages, { text: newinput, isBot: false }]);

  //   // const param = { query: newinput, filekey: selectedFile, useremail: email, chat_memory_limit: chatmemorylimit };
  //   const param={
  //       "key": awsKeyFile,
  //       "question": chatValue,
  //       "algorithm": modelOption
  //   }
  //   try {
  //     console.log(param)
  //     const response = await API.postData("/query", param);
  //     console.log("query", typeof response, response);

  //     if (typeof response === 'string') {
  //       try {
  //         response = JSON.parse(response);
  //         console.log("Parsed query_llm response:", response); // Log the parsed response
  //       } catch (e) {
  //         console.error("Failed to parse response as JSON:", e);
  //       }
  //     }

  //     if (response && response.table) {
  //       console.log("table response", response.table);
  //       const tableData = {
  //         columns: response.table.columns,
  //         data: response.table.data,
  //       };
  //       setMessage((prevMessages) => [...prevMessages, { table: tableData, tableanswer:response.answer, isBot: true }]);
  //     }
  //     else if (response && response.chart) {
  //       setMessage((prevMessages) => [...prevMessages, { chart: response.chart, chartanswer:response.answer, chartintr_steps:response.intr_steps , isBot: true }]);
  //     }
  //     else if (response && response.answer) {
  //       setMessage((prevMessages) => [...prevMessages, { text: response.answer, textintr_steps:response.intr_steps, isBot: true }]);
  //     }
  //     // else if (response && response.intr_steps) {
  //     //   setMessage((prevMessages) => [...prevMessages, { intr_steps: response.intr_steps, isBot: true }]);
  //     // }
  //   } catch (error) {
  //     console.error("Failed to get query_llm", error);
  //     setMessage((prevMessages) => [
  //       ...prevMessages,
  //       { text: "Failed to query. Refine the prompt and try again", isBot: true },
  //     ]);
  //   } finally {
  //     setLoaderOpen(false);
  //   }
  // };

  const handleSend = async () => {
    setLoaderOpen(true);
    const newinput = chatValue;
    console.log("handlesend",awsKeyFile)
    setChatValue("");

    setMessage((prevMessages) => [...prevMessages, { text: newinput, isBot: false }]);

    const param = {
      "key": awsKeyFile,
      "question": chatValue,
      "algorithm": modelOption
    };

    try {
      console.log(param);
      const response = await API.postData("/query", param);
      console.log("query", typeof response.response, response.response);

      // Check if response is an object and extract relevant data
      let newBotMessage;
      if (response && typeof response.response === 'object') {
        console.log("if block")
        if (response.table) {
          // If response has table data
          newBotMessage = { table: response.table, tableanswer: response.answer, isBot: true };
        } else if (response.chart) {
          // If response has chart data
          newBotMessage = { chart: response.chart, chartanswer: response.answer, chartintr_steps: response.intr_steps, isBot: true };
        } else if (response.answer) {
          // If response has a simple answer
          newBotMessage = { text: response.answer, textintr_steps: response.intr_steps, isBot: true };
        }
      } else if (typeof response.response === 'string') {
        console.log("else if block")
        // Handle plain string response
        newBotMessage = { text: response.response, isBot: true };
      }

      // Add the new bot message to the message state
      if (newBotMessage) {
        setMessage((prevMessages) => [...prevMessages, newBotMessage]);
      }

    } catch (error) {
      console.error("Failed to get query_llm", error);
      setMessage((prevMessages) => [
        ...prevMessages,
        { text: "Failed to query. Refine the prompt and try again", isBot: true },
      ]);
    } finally {
      setLoaderOpen(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSend();
    }
  };

  const get_userhistory = async () => {
    const bearerToken = localStorage.getItem('msal.idtoken');
    try {
      console.log("API_BASE_URL:", API_BASE_URL); 
      const resp = await axios.get(`${API_BASE_URL}/user_chats`, {
        headers: {
          Authorization: `Bearer ${bearerToken}`
        }
      });
      console.log("user history data", resp.data.data);
      console.log(typeof resp.data.data)

      if (resp && Array.isArray(resp.data.data)) {
        setUserHistory(resp.data.data);
        // console.log("Array data",userHistory)
      } else if (resp && typeof resp.data.data === "object") {
        setUserHistory(Object.values(resp.data.data));
        // Convert object to array if needed
        //  console.log("Object data",userHistory)
      }
    } catch (error) {
      console.error("Failed to get userdata", error);
    }
    finally {
    }
  };


  const handleHistClick = async (s3_location, filename) => {
    console.log("HANDLE HIST CLICK FUNCTION AWS FILE KEY", s3_location);
    console.log("HANDLE HIST CLICK FUNCTION FILENAME",filename)
    setSelectedFile(filename);
    console.log("HANDLE  HIST CLICK SELECTFILE",selectedFile)
    setAwsKeyFile(s3_location)
    console.log("HANDLE HIST CLICK aws key file",awsKeyFile)
    setMessage([
      {
        text: "Hello **" + user + "**, Let's start using **Engineering Documents Analyzer**",
        isBot: true,
        uploadbutton: true,
      },
      { text: "Currently working on: **" + filename + "**", isBot: true },
    ]);
    setLoaderOpen(true);

    const param = { key: s3_location };

    try {
      const userqueries = await API.postData("/chat_history", param);
      console.log("user file query data", typeof userqueries.chat_history);

      if (userqueries.chat_history && Array.isArray(userqueries.chat_history)) {
        const formattedMessages = userqueries.chat_history.flatMap((entry) => [
          { text: entry.question, isBot: false },
          { text: entry.response, isBot: true },
        ]);
        setMessage((prevMessages) => [...prevMessages, ...formattedMessages]);
      } else {
        console.error("chat_history is not an array or does not exist", userqueries);
        setMessage((prevMessages) => [
          ...prevMessages,
          { text: "No chat history found or an error occurred.", isBot: true },
        ]);
      }
    } catch (error) {
      console.error("Error fetching chat history", error);
      setMessage((prevMessages) => [
        ...prevMessages,
        { text: "Failed to load chat history. Please try again.", isBot: true },
      ]);
    } finally {
      setLoaderOpen(false);
    }
  };




  const reset_memory = async () => {
    setChatValue("");
    setSelectedFile(null)
    setMessage([
      {
        text: "Hello **" + user + "**, Let's start using **Engineering Documents Analyzer**",
        isBot: true,
        uploadbutton: true,
      },
    ]);
    setLoaderOpen(true)
    const param = {};
    await API.postData("/reset_memory", param);
    console.log("user history reset done");
    setUserHistory([])
    setLoaderOpen(false)
  };

  const reset_newchat = async () => {
    console.log("Reset Chat")
    setChatValue("");
    setSelectedFile(null)
    setSheetName("");
    setMessage([
      {
        text: "Hello **" + user + "**, Let's start using **Engineering Documents Analyzer**",
        isBot: true,
        uploadbutton: true,
      },
    ]);
  };

  const handleDeleteClick = async (s3_location, filename) => {
    setLoaderOpen(true);
    const bearerToken = localStorage.getItem('msal.idtoken');
    console.log("user filekey", s3_location);
    const updatedUserHistory = userHistory.filter(record => !record[filename]);
    setUserHistory(updatedUserHistory);
    console.log("selectedFile", selectedFile, "filekey", filename);

    const keyToDelete = { key: s3_location };

    try {
        // if (selectedFile && selectedFile.includes(filename)) {
        //     reset_newchat();
        // }

        const response = await axios.delete(`${API_BASE_URL}/user_chat`, {
            headers: {
                Authorization: `Bearer ${bearerToken}`,
                "Content-Type": "application/json",
            },
            data: keyToDelete,
        });


        if(response.status === 200){
          get_userhistory()
          reset_newchat()
          console.log(response)
        }
    } catch (error) {
        console.error("Error deleting chat entry:", error);
        setMessage("Failed to delete chat entry.");
        alert("Failed to delete file.");
    } finally {
        setLoaderOpen(false);
    }
};


  return (
    <ContextApp.Provider
      value={{
        setMessage,
        showSlide,
        setShowSlide,
        Mobile,
        setMobile,
        chatValue,
        setChatValue,
        handleSend,
        message,
        msgEnd,
        email,
        handleKeyPress,
        handleHistClick,
        handleDeleteClick,
        selectedFile,
        setSelectedFile,
        get_userhistory,
        userHistory,
        reset_newchat,
        sheetName,
        setSheetName,
        reset_memory,
        chatmemorylimit,
        setChatmemorylimit,
        setLoaderOpen,
        getBaseFilename,
        user,
        showLeftNav,
        toggleLeftNav,
        awsKeyFile,
        setAwsKeyFile,
        modelOption,
        setModelOption,
        updateUser
      }}
    >
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={loaderopen}
      >
        <div class="dotsloader"></div>
        {/* <CircularProgress color="inherit" /> */}
      </Backdrop>
      {children}

    </ContextApp.Provider>
  );
};

export default AppContext;
