import React, { useContext, useEffect } from "react";
import Home from "./Page/Home";
import ResponsiveAppBar from "./components/Navbar";
// import { ReactAuthWrapper } from "@carrier/reactauthwrapper";
import {ReactAuthWrapper} from "@carrier-sce-sharedpackages/reactauthwrapper"
import AppContext from "./utils/Context";
import appConfig from "./config";
import { useState } from "react";

import "./App.css";

function App() {
  const [appAccess, setAppAccess] = useState(false);


  const callback = (permissions) => {
    console.log("permissions->", permissions);
    if (permissions.appAccess) {
      console.log("permissions->", permissions.appAccess);
      setAppAccess(true);
      console.log(appAccess)
    }
  };

  useEffect(() => {
    console.log("appAccess updated->", appAccess);
  }, [appAccess]);

  return (
    <ReactAuthWrapper appConfig={appConfig.api} getPermissions={callback}>
      <AppContext>
        <div className="overflow-hidden">
          <ResponsiveAppBar />
          <Home />
        </div>
        {/* {appAccess ? 
        (
          <div className="overflow-hidden">
          <ResponsiveAppBar />
          <Home />
        </div>
        ): (
          <div></div>
        )} */}

      </AppContext>
    </ReactAuthWrapper>
  );
}

export default App;
