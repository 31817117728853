import React, { useContext, useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { BiSolidTrashAlt } from "react-icons/bi";
import { FiMessageSquare } from "react-icons/fi";
import { ContextApp } from "../utils/Context";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";

function LeftNav() {
	const {
		reset_newchat,
		showSlide,
		handleHistClick,
		handleDeleteClick,
		userHistory,
		showLeftNav,
		modelOption,
		setModelOption,
	} = useContext(ContextApp);

	const [selectedFileKey, setSelectedFileKey] = useState(null);
	const [modalOpen, setModalOpen] = useState(false);
	const [itemToDelete, setItemToDelete] = useState(null); // Store the item to delete

	if (!showLeftNav) return null;

	const handleOpenModal = (s3_location, filename) => {
		setItemToDelete({ s3_location, filename });
		setModalOpen(true);
	};

	const handleConfirmDelete = () => {
		if (itemToDelete) {
			const { s3_location, filename } = itemToDelete;
			handleDeleteClick(s3_location, filename); // Call the delete logic
		}
		setModalOpen(false); // Close the modal
		setItemToDelete(null); // Clear the selected item
	};

	return (
		<div
			className={
				!showSlide
					? "h-screen bg-gray-900 w-[300px] border-r border-gray-500 hidden lg:flex items-center justify-between text-white flex-col translate-x-0"
					: "hidden"
			}
		>
			<div style={{ marginTop: "5rem" }} className="flex items-start justify-between w-full p-2">
				<span
					style={selectedFileKey !== null ? { backgroundColor: "rgb(25 118 210)" } : {}}
					className="border border-gray-600 rounded w-[90%] py-2 text-xm flex gap-1 items-center justify-center cursor-pointer"
					onClick={() => reset_newchat()}
				>
					<AiOutlinePlus fontSize={18} />
					New Chat
				</span>
			</div>
			<div className="h-[100%] w-full p-2 flex items-start justify-start flex-col overflow-hidden overflow-y-auto text-sm scroll my-2">
				{userHistory && userHistory.length > 0 ? (
					userHistory
						.slice()
						.reverse()
						.map((historyItem, index) => {
							const { file_key, filename, s3_location } = historyItem;
							const shortfilename = filename;

							return (
								<span
									style={
										file_key === selectedFileKey
											? {
												backgroundColor: "rgb(25 118 210)",
												color: "white",
												transition: "background-color 0.3s ease, color 0.3s ease",
											}
											: {}
									}
									key={file_key}
									className="rounded w-full py-3 px-2 text-xs my-2 flex gap-1 items-center justify-between cursor-pointer hover:bg-gray-800 transition-all duration-300 overflow-hidden truncate whitespace-nowrap"
									value={file_key}
									onClick={() => {
										setSelectedFileKey(file_key); // Update selected item
										handleHistClick(s3_location, filename);
									}}
								>
									<span className="flex gap-2 items-center justify-center text-base">
										<FiMessageSquare />
										<span
											className="text-[14px] block overflow-hidden text-ellipsis whitespace-nowrap"
											style={{ maxWidth: "180px" }}
										>
											{shortfilename}
										</span>
									</span>

									{/* Delete Icon */}
									<span
										className="cursor-pointer hover:text-red-600 transition-all duration-300"
										onClick={(e) => {
											e.stopPropagation(); // Prevent click from propagating to parent element
											handleOpenModal(s3_location, filename); // Open the modal
										}}
									>
										<BiSolidTrashAlt style={{ fontSize: "1.5rem" }} />
									</span>
								</span>
							);
						})
				) : (
					<span className="w-full py-3 px-2 text-xs my-2 text-center"></span>
				)}
			</div>
			{/* Bottom Section */}
			<div className="w-full border-t border-gray-900 flex flex-col gap-1 items-center justify-center p-3">
				<span className="flex items-center justify-between w-full text-sm">
					<span className="font-medium">Model</span>

					<select
						className="bg-gray-700 text-white rounded-md px-5 py-1"
						onChange={(e) => setModelOption(e.target.value)} // Replace with your state handler
					>
						<option value="llama">LLAMA</option>
						<option value="mistral">MISTRAL</option>
					</select>
				</span>
			</div>

			{/* Confirmation Modal */}
			<Dialog
				open={modalOpen}
				onClose={() => setModalOpen(false)}
				PaperProps={{
					style: {
						borderRadius: '12px',
						boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
					},
				}}
			>
				<DialogTitle style={{ fontWeight: "bold", fontSize: "1.25rem" }}>
					Confirm Delete
				</DialogTitle>
				<DialogContent>
					<p style={{ fontSize: "1rem", color: "#555" }}>
						Are you sure you want to delete this item? This action cannot be undone.
					</p>
				</DialogContent>
				<DialogActions style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
					<Button
						onClick={() => setModalOpen(false)}
						style={{
							backgroundColor: "#f0f0f0",
							color: "#333",
							borderRadius: "5px",
							padding: "8px 16px",
							fontWeight: "bold",
							textTransform: "none",
						}}
					>
						Cancel
					</Button>
					<Button
						onClick={handleConfirmDelete}
						style={{
							backgroundColor: "#d32f2f",
							color: "#fff",
							borderRadius: "5px",
							padding: "8px 16px",
							fontWeight: "bold",
							textTransform: "none",
						}}
					>
						Delete
					</Button>
				</DialogActions>
			</Dialog>

		</div>
	);
}

export default LeftNav;
