import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import '../App.css';

function CustomSnackbar({ open, onClose, message, snackbarType }) {
    return (
        <Snackbar
            open={open}
            autoHideDuration={13000}
            onClose={onClose}
            classes={{ root: 'snackbar-custom-position' }}
        >

            <Alert
                onClose={onClose}
                severity={snackbarType}
                style={{ width:"100%", backgroundColor:"#E8E8E8", borderRadius:'12px'}}
                className="snackbar-custom-alert"
            >
                {message}
            </Alert>
        </Snackbar>
    );
}

export default CustomSnackbar;
